/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Nav, Button, Form, Modal } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import Select from 'react-select'
import request from '../../../services/AxiosInstance';
import { toast } from 'react-toastify';
import { locationOption, directionOpt, BOX_TYPE, portOpt, COOL_UNIT_TEMP, } from '../../../constant/locationConstant';
import { langOptions, kioskWeekArray } from '../../../constant/AccountConstant';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/bootstrap/Loader';
import { timeMask } from '../../../helper/helper';

const Locations = ({ rowData, type, setType }) => {
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);
	const [boxData, setBoxData] = useState('');
	const [accountValues, setAccountValues] = useState([]);
	const [parentId, setParentId] = useState('')
	const [locationType, setLocationType] = useState('')
	const [boxOpt, setBoxOpt] = useState([]);
	const [boxTypes, setBoxTypes] = useState('');
	const [locationName, setLocationName] = useState('');
	const [description, setDescription] = useState('');
	const [locationNotes, setLocationNotes] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [locationImg, setLocationImg] = useState('');
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [boxId, setBoxId] = useState('');
	const [preferredLang, setPreferredLang] = useState('');
	const [addonUnits, setAddonUnits] = useState([]);
	const [settingModal, setSettingModal] = useState(false);
	const [maxTemp, setMaxTemp] = useState();
	const [targetTemp, setTargetTemp] = useState();
	const [minTemp, setMinTemp] = useState();
	const [port, setPort] = useState();
	const [boxIndex, setBoxIndex] = useState();
	const [errorMaxTemp, setErrorMaxTemp] = useState(false);
	const [errorTargetTemp, setErrorTargetTemp] = useState(false);
	const [errorMinTemp, setErrorMinTemp] = useState(false);
	const [kioskHoursOfOperaionsArray, setKioskHoursOfOperaionsArray] = useState(kioskWeekArray)
	const [isInstallMode, setIsInstallMode] = useState(false);
	const [externalId, setExternalId] = useState('');

	const handleReset = () => {
		setLoader(false);
		setAccountValues([]);
		setParentId('');
		setLocationType('');
		setBoxOpt([]);
		setBoxTypes('');
		setLocationName('');
		setDescription('');
		setLocationNotes('');
		setIsActive(false);
		setLocationImg('');
		setAddressLine1('');
		setAddressLine2('');
		setCity('');
		setState('');
		setZip('');
		setBoxId('');
		setPreferredLang('');
		setAddonUnits([]);
		setSettingModal(false);
		setMaxTemp(undefined);
		setTargetTemp(undefined);
		setMinTemp(undefined);
		setPort(undefined);
		setBoxIndex(undefined);
		setErrorMaxTemp(false);
		setErrorTargetTemp(false);
		setErrorMinTemp(false);
		setKioskHoursOfOperaionsArray(kioskWeekArray);
		setExternalId("");
	};


	const onDrop = useCallback(acceptedFiles => {
		const file = acceptedFiles[0];
		const fileWithPreview = Object.assign(file, {
			preview: URL.createObjectURL(file)
		});
		setLocationImg(fileWithPreview)
	}, [])

	const handleRemoveImage = () => setLocationImg(null);
	const { getRootProps } = useDropzone({ onDrop })

	const handleAddOn = () => {
		setAddonUnits([
			...addonUnits,
			{ id: "", type: "", side: "", connect: "" },
		]);
	}

	const handleRemoveAddOn = (val) => {
		if (type === "Edit") {
			const deletedExtension = addonUnits[val].id;
			const removeRes = request('get', `remove/extension/${deletedExtension}`)
			if (removeRes.message) {
				toast.error(removeRes.message)
				return;
			}
			const updatedAddOn = addonUnits.filter((_, sidx) => val !== sidx);
			setAddonUnits(updatedAddOn)
		} else {
			const updatedAddOn = addonUnits.filter((_, index) => index !== val);
			setAddonUnits(updatedAddOn);
		}
	}

	const getAccounts = async () => {
		const accResData = await request('get', 'accounts');
		if (accResData.message) {
			toast.error(accResData.message)
			return;
		}
		const accountValues = accResData.map((account) => {
			return { value: account.id, label: account.name };
		});
		setAccountValues(accountValues)
		setParentId(accountValues[0])
	}

	const getBoxType = async () => {
		const boxTypeData = await request('get', 'box-types');
		if (boxTypeData.message) {
			toast.error(boxTypeData.message)
			return;
		}
		const typeValues = boxTypeData.map((type) => {
			return { value: type.id, label: type.name };
		})
		setBoxOpt(typeValues)
		if (rowData && rowData.length > 0) {
			let [data] = rowData
			const boxTypes = typeValues.find(option => option.value === data.type_id);
			setBoxTypes(boxTypes);
		}
	}

	const handleKioskTimeChange = (event, index, propertyName) => {
		const updatedHoursOfOperation = kioskHoursOfOperaionsArray && kioskHoursOfOperaionsArray.map((item, idx) => {
			if (idx !== index) return item;
			return {
				id: idx,
				value: item.value,
				openTime: propertyName === "openTime" ? event.target.value : item.openTime,
				closeTime: propertyName === "closeTime" ? event.target.value : item.closeTime,
				isClosed: propertyName === "isClosed" ? event.target.checked : item.isClosed,
				isOpenAll: propertyName === "isOpenAll" ? event.target.checked : item.isOpenAll
			};
		});
		setKioskHoursOfOperaionsArray(updatedHoursOfOperation);
	};

	const onChangeBoxType = (evt, idx) => {
		const boxType = addonUnits && addonUnits.map((item, sidx) => {
			if (idx !== sidx) return item;
			return {
				id: type === "Edit" ? item.id : sidx,
				expansion_id: evt.target.value,
				direction: item.direction,
				connected_box_id: item.connected_box_id,
			};
		});
		setAddonUnits(boxType)
	};

	const onChangeUnitDirection = (evt, idx) => {
		const unitDirection = addonUnits && addonUnits.map((item, sidx) => {
			if (idx !== sidx) return item;
			return {
				id: sidx,
				expansion_id: item.expansion_id,
				direction: parseFloat(evt.target.value),
				connected_box_id: item.connected_box_id,
			};
		});
		setAddonUnits(unitDirection)
	};

	const onChangeConnectedDevice = (evt, idx) => {
		const connectedDevice = addonUnits && addonUnits.map((item, sidx) => {
			if (idx !== sidx) return item;
			return {
				id: sidx,
				expansion_id: item.expansion_id,
				direction: item.direction,
				connected_box_id: evt.target.value,
			};
		});
		setAddonUnits(connectedDevice)
	};

	const convertAccountHoursOfOperationsObject = async (selectedObject) => {
		if (![undefined, 'undefined', null, 'null', ''].includes(selectedObject)) {
			const {
				id,
				account_id,
				created_on,
				deleted_on,
				modified_on,
				is_deleted,
				is_enabled,
				closed_msg,
				closedimg_url,
				hop_type,
				box_id,
				lunch_start,
				lunch_end,
				...filteredObject
			} = selectedObject;
			return filteredObject;
		}
	};

	const hanldeAccountHoursOfOperations = async (selectedObject) => {
		let AccountHoursOfOperationArray = [];
		if (
			selectedObject !== '' &&
			selectedObject !== null &&
			selectedObject !== 'null' &&
			selectedObject !== undefined &&
			selectedObject !== 'undefined'
		) {
			const Keys = Object.keys(selectedObject);
			const Values = Object.values(selectedObject);
			if (Keys && Keys.length && Keys.length > 0) {
				for (let i = 0; i < Keys.length; i++) {
					const KeyName = Keys && Keys[i];
					const HoursOfTime = Values && Values[i] && Values[i].split(',');
					AccountHoursOfOperationArray.push({
						id: i,
						value: KeyName[0].toUpperCase() + KeyName.slice(1),
						openTime: HoursOfTime && HoursOfTime[0],
						closeTime: HoursOfTime && HoursOfTime[1],
						isClosed: HoursOfTime && HoursOfTime[2],
						isOpenAll: HoursOfTime && HoursOfTime[3],
					});
				}
			}
		}
		return AccountHoursOfOperationArray;
	};

	const handleKioskHour = async (kiosk_hoursof_operations) => {
		const KioskHOPObject = await convertAccountHoursOfOperationsObject(kiosk_hoursof_operations[0]);
		const KioskHoursOfOperations = await hanldeAccountHoursOfOperations(KioskHOPObject);
		setKioskHoursOfOperaionsArray(KioskHoursOfOperations)
	}

	const handleCloseSettings = () => {
		setMaxTemp('');
		setTargetTemp('');
		setMinTemp('');
		setPort('');
		setSettingModal(false);
	};

	const getSettings = (expansionID) => {
		let obj = addonUnits && addonUnits.filter((x) => x.id === expansionID);
		if (obj && obj.length > 0) {
			setMaxTemp(obj[0].max_temp);
			setTargetTemp(obj[0].target_temp);
			setMinTemp(obj[0].min_temp);
			setPort(obj[0].com_port);
		}
	};

	const handleSeetingsforCoolUnit = (id, index) => {
		setSettingModal(true);
		setBoxIndex(index);
		if (type === 'Edit' || type === 'View') {
			getSettings(id);
		}
	};

	const saveCoolBoxSettings = () => {
		if (
			maxTemp > COOL_UNIT_TEMP.MAX_TEMP ||
			maxTemp < COOL_UNIT_TEMP.MIN_TEMP ||
			maxTemp < minTemp
		) {
			setErrorMaxTemp(true);
		} else if (targetTemp > maxTemp || targetTemp < minTemp) {
			setErrorTargetTemp(true);
		} else if (
			minTemp > COOL_UNIT_TEMP.MAX_TEMP ||
			minTemp < COOL_UNIT_TEMP.MIN_TEMP ||
			minTemp > maxTemp
		) {
			setErrorMinTemp(true);
		} else {
			const settings = addonUnits.map((item, sidx) => {
				if (boxIndex !== sidx) return item;
				return {
					id: type === 'Edit' ? item.id : sidx,
					expansion_id: item.expansion_id,
					direction: item.direction,
					connected_box_id: item.connected_box_id,
					max_temp: maxTemp,
					target_temp: targetTemp,
					min_temp: minTemp,
					com_port: port
				};
			});
			setAddonUnits(settings);
			setErrorMaxTemp(false);
			setErrorTargetTemp(false);
			setErrorMinTemp(false);
			setSettingModal(false);
		}
	};

	const handleSubmit = () => {
		setLoader(true);
		let data = new FormData();
		data.append('parent_account_id', parentId && parentId.value);
		data.append('name', locationName);
		data.append('type_id', boxTypes && boxTypes.value);
		data.append('description', description);
		data.append('location_type_id', locationType && locationType.value);
		data.append('location_notes', locationNotes);
		data.append('IsActive', isActive);
		data.append('file', locationImg);
		data.append('address_line_1', addressLine1);
		data.append('address_line_2', addressLine2);
		data.append('city', city);
		data.append('state', state);
		data.append('zip', zip);

		data.append('kiosk_box_id', boxId);
		data.append('preferredlanguage', preferredLang && preferredLang.value);
		data.append('kiosk_hoursof_operations', JSON.stringify(kioskHoursOfOperaionsArray));

		data.append("HopType", "BoxLevel");
		data.append("box_expansions", JSON.stringify(addonUnits));
		data.append("is_install_mode", isInstallMode);

		data.append('external_id', externalId);
		if (!['View', 'Edit'].includes(type)) {
			const result = fetch(process.env.REACT_APP_BASEURL + "boxes", {
				method: "POST",
				withCredentials: true,
				headers: {
					Authorization:
						"Bearer " +
						JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken
							.jwtToken,
				},
				body: data,
			});
			result
				.then((result) => {
					result.json().then((res) => {
						setLoader(false)
						if (res.error) {
							toast.error(res.error.message)
							return;
						} else {
							handleReset();
							navigate('/location-list')
							toast.success("Location added successfully !!")
						}
					});
				})
				.catch((error) => {
					setLoader(false)
					toast.error(error.message)
				});
		} else {
			data.append('id', boxData.id)
			data.append('boxId', boxData.id)
			const result = fetch(process.env.REACT_APP_BASEURL + "boxes", {
				method: "PUT",
				withCredentials: true,
				headers: {
					Authorization:
						"Bearer " +
						JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken
							.jwtToken,
				},
				body: data,
			});
			result
				.then((result) => {
					result.json().then(() => {
						setType('');
						handleReset()
						toast.success("Location updated !!");
					});
				})
				.catch((error) => {
					setLoader(false)
					toast.error(error.message)
				});
		}
	}

	useEffect(() => {
		getAccounts();
		getBoxType();
		if (rowData && rowData.length > 0) {
			let [data] = rowData
			setBoxData(data);
			setLocationName(data.name);
			setDescription(data.description);
			setLocationNotes(data.location_notes);
			setIsActive(data.IsActive);
			setLocationImg(data.picture_url);
			setAddressLine1(data.address && data.address.address_line_1)
			setAddressLine2(data.address && data.address.address_line_2)
			setCity(data.address && data.address.city)
			setState(data.address && data.address.state)
			setZip(data.address && data.address.zip)
			setBoxId(data.kiosk_box_id);
			setAddonUnits(data.box_expansions);
			setIsInstallMode(data.is_install_mode);
			setExternalId(data.external_id)

			const locationType = locationOption.find(option => option.value === data.location_type_id);
			setLocationType(locationType);

			const selectedLang = langOptions.find(option => option.value === data.preferred_language);
			setPreferredLang(selectedLang);
			const _kiosk_hoursof_operations = data && data.kiosk_hoursof_operations.filter(item => item.hop_type === 'BoxLevel' && item.box_id === data.id)
			if (_kiosk_hoursof_operations && _kiosk_hoursof_operations.length > 0) {
				handleKioskHour(_kiosk_hoursof_operations);
			} else {
				setKioskHoursOfOperaionsArray(kioskWeekArray)
			}

		}
	}, [])

	const checkIfExternalIdExist = (e) => {
		e && e.preventDefault();
		try {
			fetch(process.env.REACT_APP_BASEURL + "boxes/external-id-check", {
				method: "POST",
				withCredentials: true,
				headers: {
				Authorization:
					"Bearer " +
					JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken
					.jwtToken,
				"Content-Type": "application/json",
				},
				body: JSON.stringify({
					table: "boxes",
					column: "external_id",
					value: externalId,
					lang: localStorage.getItem("i18nextLng") || 'en',
				}),
			})
				.then((response) => response.json())
				.then((idCheckResponse) => {
				console.log({ idCheckResponse });
				if (idCheckResponse.statusCode === 200) {
					toast.error(idCheckResponse && idCheckResponse.message);
				}
				})
				.catch((err) => {
				toast.error(
					(err && err.message) ||
					`Error while checking external id exists or not.`
				);
				});
		} catch (error) {
			toast.error(`Failed to validate external id`);
		}
	};
	
	const boxOPt1 = boxOpt?.filter(({ value }) => [BOX_TYPE.L60, BOX_TYPE.HD36, BOX_TYPE.C30].includes(value));
	return (
		<div className='position-relative'>
			{loader ? <div className="loader">
				<Loader />
			</div> : ""}
			<Tab.Container defaultActiveKey='DETAILS'>
				<Nav as='ul' className='nav-tabs'>
					<Nav.Item as='li' >
						<Nav.Link eventKey={'DETAILS'}>
							Location Detail
						</Nav.Link>
					</Nav.Item>
					<Nav.Item as='li' >
						<Nav.Link eventKey={'SETTINGS'}>
							SETTINGS
						</Nav.Link>
					</Nav.Item>
					<Nav.Item as='li' >
						<Nav.Link eventKey={'ADD-ONS'}>
							ADD-ONS
						</Nav.Link>
					</Nav.Item>
				</Nav>

				<Tab.Content className='pt-4'>
					{/* DETAILS */}
					<Tab.Pane eventKey='DETAILS'>
						<div className='row'>
							<div className='col-xl-12 col-lg-12'>
								<h3 className="my-3 fw-bold">Add Locations</h3>
							</div>
							<div className='col-xl-8 col-lg-8'>
								<div className='row'>
									<div className='col-xl-4 col-lg-4'>
										<div className="form-group mb-3">
											<label>Parent Account</label>
											<Select
												value={parentId}
												onChange={(val) => setParentId(val)}
												options={accountValues}
												style={{
													lineHeight: '40px',
													color: '#7e7e7e',
													paddingLeft: ' 15px',
												}}
												disabled={type === 'View'}
											/>
										</div>
									</div>
									<div className='col-xl-4 col-lg-4'>
										<div className="form-group mb-3">
											<label>Location Type</label>
											<Select
												value={locationType}
												onChange={(val) => setLocationType(val)}
												options={locationOption}
												style={{
													lineHeight: '40px',
													color: '#7e7e7e',
													paddingLeft: ' 15px',
												}}
											/>
										</div>
									</div>
									<div className='col-xl-4 col-lg-4'>
										<div className="form-group mb-3">
											<label>Box Type</label>
											<Select
												value={boxTypes}
												onChange={(val) => setBoxTypes(val)}
												options={boxOpt}
												style={{
													lineHeight: '40px',
													color: '#7e7e7e',
													paddingLeft: ' 15px',
												}}
											/>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4">
										<div className="form-group mb-3">
											<label>Location Name</label>
											<input
												type="text"
												value={locationName}
												disabled={type === 'View'}
												onChange={(e) => setLocationName(e.target.value)}
												className="form-control  form-control-sm fs-16"
											/>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4">
										<div className="form-group mb-3">
											<label>Description</label>
											<input
												type="text"
												value={description}
												disabled={type === 'View'}
												onChange={(e) => setDescription(e.target.value)}
												className="form-control  form-control-sm fs-16"
											/>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4">
										<div className="form-group mb-3">
											<label>Location Notes</label>
											<input
												type="text"
												value={locationNotes}
												disabled={type === 'View'}
												onChange={(e) => setLocationNotes(e.target.value)}
												className="form-control  form-control-sm fs-16"
											/>
										</div>
									</div>
									<div className='col-xl-3 col-lg-4'>
										<label>Active</label>
										<label className="toggle-switch">
											<input
												type="checkbox"
												checked={isActive}
												disabled={type === 'View'}
												onChange={() => setIsActive(!isActive)}
												className='form-check-input mx-4'
											/>
											<span className=".form-check-label"></span>
										</label>
									</div>
								</div>
							</div>
							<div className='col-xl-4 col-lg-4'>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<div id='avatarImg' {...getRootProps()}>
										{locationImg ? (
											<div className='bx-shd'>
												<img className="rounded-3" src={locationImg.preview ? URL.createObjectURL(locationImg) : locationImg} height='120px' width='120px' alt="AccountImage" />
											</div>
										) : (<div className='bx-shd'> <svg focusable="false" aria-hidden="true" width="100px" height="100px" viewBox="0 0 24 24" data-testid="CloudUploadIcon">
											<path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3" fill="green" />
										</svg>
										</div>)}
									</div>
									<label className='pt-1'>Upload Image</label>
									<Button variant='info tp-btn btn-sm w-50' onClick={() => handleRemoveImage('account')}>REMOVE IMAGE</Button>
								</div>
							</div>
							<div className='col-xl-12 col-lg-12'>
								<h3 className='my-3 fw-bold'>Location Address</h3>
							</div>
							<div className="col-xl-4 col-lg-4">
								<div className="form-group mb-3">
									<label>Address Line 1</label>
									<input
										type="text"
										value={addressLine1}
										disabled={type === 'View'}
										onChange={(e) => setAddressLine1(e.target.value)}
										className="form-control  form-control-sm fs-16"
									/>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4">
								<div className="form-group mb-3">
									<label>Address Line 2</label>
									<input
										type="text"
										value={addressLine2}
										disabled={type === 'View'}
										onChange={(e) => setAddressLine2(e.target.value)}
										className="form-control  form-control-sm fs-16"
									/>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4">
								<div className="form-group mb-3">
									<label>City</label>
									<input
										type="text"
										value={city}
										disabled={type === 'View'}
										onChange={(e) => setCity(e.target.value)}
										className="form-control  form-control-sm fs-16"
									/>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4">
								<div className="form-group mb-3">
									<label>State</label>
									<input
										type="text"
										value={state}
										disabled={type === 'View'}
										onChange={(e) => setState(e.target.value)}
										className="form-control  form-control-sm fs-16"
									/>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4">
								<div className="form-group mb-3">
									<label>Zip</label>
									<input
										type="text"
										value={zip}
										disabled={type === 'View'}
										onChange={(e) => setZip(e.target.value)}
										className="form-control  form-control-sm fs-16"
									/>
								</div>
							</div>
						</div>
					</Tab.Pane>

					{/* SETTINGS */}
					<Tab.Pane eventKey='SETTINGS'>
						<div className='row'>
							<div className='col-xl-4 col-lg-4'>
								<div className='row'>
									<label>Box Id</label>
								</div>
								<div className='row d-flex'>
									<div className='col-8'>
										<input
											type="text"
											value={boxId}
											disabled={type === 'View'}
											onChange={(e) => setBoxId(e.target.value)}
											className="form-control  form-control-sm fs-16"
										/>
									</div>
									<div className='col-4'>
										<Button
											className='fs-12 btn-sm text-black me-2'
										>
											<i class="fa-solid fa-pencil" />
										</Button>
										<Button
											className='fs-12 btn-sm text-black me-2'
										>
											<i class="fa-solid fa-floppy-disk"></i>
										</Button>
									</div>
								</div>
								<div className='row'>
									<label>Preferred Language</label>
								</div>
								<div className='row'>
									<div className='col-8'>
										<Select
											value={preferredLang}
											onChange={(val) => setPreferredLang(val)}
											options={langOptions}
											style={{
												lineHeight: '40px',
												color: '#7e7e7e',
												paddingLeft: ' 15px',

											}} />
									</div>
								</div>
								<div className='row' style={{ marginTop: '10px' }}>
									<div className='col-xl-3 col-lg-4 d-flex align-items-center'>
										<label className="mr-2" style={{ whiteSpace: 'nowrap' }}>Install Mode</label>
										<label className="toggle-switch" style={{ marginBottom: "4px" }}>
											<input
												type="checkbox"
												checked={isInstallMode}
												disabled={type === 'View'}
												onChange={() => setIsInstallMode(!isInstallMode)}
												className='form-check-input mx-2'
											/>
											<span className="form-check-label"></span>
										</label>
									</div>
								</div>
								<div className='row'>
									<label>External Id</label>
								</div>
								<div className='row'>
									<div className='col-8'>
										<input
											type="text"
											value={externalId}
											disabled={type === 'View'}
											onChange={(e) => setExternalId(e.target.value)}
											className="form-control  form-control-sm fs-16"
											onBlur={(e) => {
												externalId && externalId.length > 0 && checkIfExternalIdExist(e)
											}}
										/>
									</div>
								</div>
							</div>

							<div className="col-xl-6 col-lg-6">
								<h3 className="my-3 fw-bold d-flex">Location Business Hours
									<div
										data-container="body"
										data-toggle="popover"
										className="pl-2 tooltip1"
										title="Set the hours and days your kiosk is open for business."
										data-content="Popover body content is set in this attribute."
									>
										<svg focusable="false" width="25px" height="25px" aria-hidden="true" viewBox="0 0 24 24" data-testid="HelpIcon" aria-label="Set the hours and days your kiosk is open for business.">
											<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z">
											</path>
										</svg>
									</div>
								</h3>
								<div className="row my-2 fw-bold">
									<div className="col-1 fw-bold">Days</div>
									<div className="col-3 fw-bold text-end">Open-All Day</div>
									<div className="col-3 fw-bold">Open-Time</div>
									<div className="col-3 fw-bold">Close-Time</div>
									<div className="col-2 fw-bold">Closed</div>
								</div>
								{kioskHoursOfOperaionsArray.map((ele, index) => {
									return (
										<div className="row mb-3 flex flex-row justify-content-center align-items-center" key={ele.id}>
											<div className="col-2">
												<h4>{ele.value}</h4>
											</div>
											<div className="col-2">
												<input
													type="checkbox"
													onChange={(e) => handleKioskTimeChange(e, index, 'isOpenAll')}
													disabled={[true, "true"].includes(ele.isClosed) || type === 'View'}
													checked={[true, "true"].includes(ele.isOpenAll) ? true : false}
													className="form-check-input"
												/>
											</div>
											<div className="col-3">
												<input
													maxLength={5}
													value={timeMask(ele.openTime)}
													onChange={(e) => handleKioskTimeChange(e, index, 'openTime')}
													disabled={
														[true, "true"].includes(ele.isClosed) || [true, "true"].includes(ele.isOpenAll) || type === 'View'
													}
													className="form-control form-control-sm fs-16"
												/>
											</div>
											<div className="col-3">
												<input
													maxLength={5}
													value={timeMask(ele.closeTime)}
													onChange={(e) => handleKioskTimeChange(e, index, 'closeTime')}
													disabled={
														[true, "true"].includes(ele.isClosed) || [true, "true"].includes(ele.isOpenAll) || type === 'View'
													}
													className="form-control form-control-sm fs-16"

												/>
											</div>
											<div className="col-2">
												<input
													type="checkbox"
													onChange={(e) => handleKioskTimeChange(e, index, 'isClosed')}
													disabled={[true, "true"].includes(ele.isOpenAll) || type === 'View'}
													checked={[true, "true"].includes(ele.isClosed) ? true : false}
													className="form-check-input"
												/>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</Tab.Pane>

					{/* ADD-ONS */}
					<Tab.Pane eventKey='ADD-ONS' key={2}>
						<div className='row align-items-center'>
							<div className='col-2'>
								<Button
									className='fs-12 btn-sm text-black'
									onClick={() => handleAddOn()}
								>
									<i class="fa-solid fa-plus" />
								</Button>
							</div>
							<div className='col-4'>
								<h4>Type</h4>
							</div>
							<div className='col-2'>
								<div className='fs-22 text-black d-flex justify-content-around'>
									<i class="fa-solid fa-arrow-left"></i>
									<i class="fa-solid fa-arrow-right"></i>
								</div>
							</div>
							<div className='col-4'>
								<h4>Connected</h4>
							</div>
						</div>

						{addonUnits.map((val, index) => (
							<div className='row align-items-center pt-2' key={index}>
								<div className='col-2'>
									{val.expansion_id === BOX_TYPE.Cool ? (
										<Button
											onClick={() => handleSeetingsforCoolUnit(val.id, index)}
											className='fs-12 btn-sm text-black me-2'
										>
											<i class="fa-solid fa-gear" />
										</Button>) : ""}
									<Button className='fs-12 btn-sm text-black me-2'
										onClick={() => handleRemoveAddOn(index)}
									>
										<i class="fa-solid fa-minus" />
									</Button>
								</div>
								<div className='col-4'>
									<label>Box Type</label>
									<Form.Select
										value={val.expansion_id}
										onChange={(e) => onChangeBoxType(e, index)}
									>
										<option value='' />
										{boxOPt1.map((type) => {
											return (
												<option className='fs-16' value={type.value}>{type.label}</option>
											)
										})}
									</Form.Select>
								</div>
								<div className='col-2'>
									<label>Side</label>
									<Form.Select
										value={val.direction}
										onChange={(e) => onChangeUnitDirection(e, index)}
									>
										<option value='' />
										{directionOpt.map((type) => {
											return (
												<option className='fs-16' value={type.value}>{type.label}</option>
											)
										})}
									</Form.Select>
								</div>
								<div className='col-4'>
									<label>Connect</label>
									<Form.Select
										value={val.connected_box_id}
										onChange={(e) => onChangeConnectedDevice(e, index)}
									>
										<option value='' />
										{boxOpt.map((type) => {
											return (
												<option className='fs-16' value={type.value}>{type.label}</option>
											)
										})}
									</Form.Select>
								</div>
							</div>
						))}
					</Tab.Pane>
				</Tab.Content>
				<div className='row pt-5'>
					<div className='col-12 text-end'>
						<Button
							onClick={() => ['View', 'Edit'].includes(type) ? setType('') : navigate('/location-list')}
							className='me-2 btn-sm' variant='primary'>
							CANCEL
						</Button>
						{type !== 'View' ?
							<Button onClick={() => handleSubmit()} variant='primary btn-sm mx-2'>{type === 'Edit' ? "Update" : "Add"}</Button> : ''}
					</div>
				</div>
			</Tab.Container>
			<Modal className="fade" show={settingModal} centered>
				<Modal.Header>
					<Modal.Title>Settings</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<label>Max Temp Alert</label>
						<input
							type="text"
							value={maxTemp}
							disabled={type === 'View'}
							onChange={(e) => setMaxTemp(e.target.value.replace(/\D/g, ''))}
							className="form-control  form-control-sm fs-16"
						/>
						{errorMaxTemp && (
							<div className="text-danger fs-12">Enter Valid Max Temp</div>
						)}
						<label>Target Temperature</label>
						<input
							type="text"
							value={targetTemp}
							disabled={type === 'View'}
							onChange={(e) => setTargetTemp(e.target.value.replace(/\D/g, ''))}
							className="form-control  form-control-sm fs-16"
						/>
						{errorTargetTemp && (
							<div className="text-danger fs-12">Enter Valid Target Temp</div>
						)}
						<label>Min Temp Alert</label>
						<input
							type="text"
							value={minTemp}
							disabled={type === 'View'}
							onChange={(e) => setMinTemp(e.target.value.replace(/\D/g, ''))}
							className="form-control  form-control-sm fs-16"
						/>
						{errorMinTemp && (
							<div className="text-danger fs-12">Enter Valid Min Temp</div>
						)}
						<label>Port</label>
						<Form.Select
							value={port}
							defaultValue={"COM2"}
							onChange={(e) => setPort(e.target.value)}
						>
							<option value='' />
							{portOpt.map((type) => {
								return (
									<option className='fs-16' value={type.value}>{type.label}</option>
								)
							})}
						</Form.Select>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="btn-sm"
						variant="danger light"
						onClick={() => handleCloseSettings()}
					>
						Close
					</Button>
					<Button
						className="btn-sm"
						variant="primary"
						onClick={() => saveCoolBoxSettings()}
					>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}
export default Locations;