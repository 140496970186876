import React from "react";
import BOX_TYPE from './../../../constant/BoxTypes';
import BOX_STATUS from "../../../constant/BoxStatus";
import GroupTypes from "../../../constant/GroupTypes";
import { CiLock } from "react-icons/ci";

let nums1 = Array.from(Array(12).keys());
let nums3 = Array.from(Array(3).keys());
let BinIds = [];
let IsSaved;

const C30Layout = ({
  BinStatus,
  deactivatedBins,
  getPrescriptionDetails,
  Selectedbin,
  isViewPermissionAllowed,
  box_position,
  expansion,
  ExpansionsCounts,
  boxTypeId,
}) => {
  const checkIsSaved = (binId) => {
    if (BinStatus.length > 0) {
      BinStatus.map((binitem) => {
        if (binitem.bin_id === binId) {
          IsSaved = true;
        } else {
          IsSaved = false;
        }
        return true;
      });
    } else {
      IsSaved = false;
    }
  };

  const openbin = (binId, status, pm_id) => {
    if (status === "Assigned" || status === "Stocked" || status === "Hold") {
      if (deactivatedBins?.includes(binId)) {
        Selectedbin(binId, box_position);
        let items = document.getElementById(binId);
        items.style.background =
          "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)"; // deavtivated
        items.style.color = "#ffffff";
      }
      getPrescriptionDetails(binId, pm_id);
    } else {
      BinIds.map((item) => {
        checkIsSaved(item);
        if (item !== binId && IsSaved === false) {
          var items = document.getElementById(item);
          items.style.background =
            "linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)";
        }
        return true;
      });
      BinIds = [];
      BinIds.push(binId);
      if (BinIds.length > 0) {
        Selectedbin(binId, box_position);
        var items = document.getElementById(binId);
        items.style.background =
          "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)";
      }
    }
  };

  const getColumns = (
    currentIndex,
    currentColumnCount,
    previousColumnCount,
    side
  ) => {
    let columns = [];
    if (side === "L") {
      for (
        let i = previousColumnCount + currentColumnCount;
        i > previousColumnCount;
        i--
      ) {
        columns.push(
          String.fromCharCode(65 + (i - 1)) + String.fromCharCode(65 + (i - 1))
        );
      }
    } else {
      for (
        let i = previousColumnCount;
        i < previousColumnCount + currentColumnCount;
        i++
      ) {
        columns.push(String.fromCharCode(65 + (i - 1)));
      }
    }
    return columns;
  };

  const getCurrentAndPreviousPosition = (side) => {
    let response = {};
    response.currentCount = expansion.bin_column_count;
    if (side === "L") {
      let _expansionsCount =
        ExpansionsCounts && ExpansionsCounts.leftExpansions;
      if (expansion.box_position === -1 || expansion.box_position === "-1") {
        response.previousCount = 0;
      } else {
        response.previousCount = excludeCurrentExpansion(
          expansion,
          _expansionsCount
        );
      }
    } else {
      let _expansionsCount = ExpansionsCounts.rightExpansions;
      if (expansion.box_position === 1 || expansion.box_position === "1") {
        if (boxTypeId === BOX_TYPE.Locker) {
          response.previousCount = 6;
        } else {
          response.previousCount = 4;
        }
      } else {
        if (boxTypeId === BOX_TYPE.Locker) {
          response.previousCount =
            excludeCurrentExpansion(expansion, _expansionsCount) + 6;
        } else {
          response.previousCount =
            excludeCurrentExpansion(expansion, _expansionsCount) + 4;
        }
      }
    }
    return response;
  };

  const excludeCurrentExpansion = (expansion, expansionArray) => {
    let currentUnitIndex = null;
    let sumOfColumns = 0;
    for (let index = 0; index < expansionArray.length; index++) {
      if (expansionArray[index].expansionId === expansion.id) {
        currentUnitIndex = index;
        break;
      }
    }
    for (let i = 0; i < currentUnitIndex; i++) {
      if (expansionArray[i - 1] !== undefined) {
        sumOfColumns =
          expansionArray[i].bin_column_count +
          expansionArray[i - 1].bin_column_count;
      } else {
        sumOfColumns = expansionArray[i].bin_column_count;
      }
    }
    return sumOfColumns;
  };

  let elem3 = [];
  let side = box_position < 0 ? "L" : "R";
  if (side === "L") {
    let prevNextCount = getCurrentAndPreviousPosition(side);
    let previousExpansionCount =
      prevNextCount && prevNextCount.previousCount
        ? prevNextCount.previousCount
        : 0;
    elem3 = getColumns(
      box_position,
      prevNextCount && prevNextCount.currentCount,
      previousExpansionCount,
      side
    );
  } else {
    let prevNextCount = getCurrentAndPreviousPosition(side);
    let previousExpansionCount =
      prevNextCount && prevNextCount.previousCount
        ? prevNextCount.previousCount
        : 0;
    elem3 = getColumns(
      box_position,
      prevNextCount && prevNextCount.currentCount,
      previousExpansionCount,
      side
    );
  }

  const isRestricted = (groupTypeId) => {
    if (![undefined, 'undefined', null, 'null', ""].includes(groupTypeId)) {
      return [GroupTypes.Audiology, GroupTypes.Pharmacy, GroupTypes.Super, GroupTypes.eCommerce].includes(groupTypeId) ? true : false;
    } else {
      return false;
    }
  };

  const getColor = (statusTitle) => {
    let styleObject = {
      background: null,
      color: ''
    };
    switch (statusTitle) {
      case "ASSIGNED":
        styleObject.color = '#000';
        styleObject.background =
          "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)";
        break;
      case "STOCKED":
        styleObject.color = '#000';
        styleObject.background =
          "linear-gradient(155deg, rgb(23,166,0) 0%, rgb(196,255,189) 90%)";
        break;
      case "HOLD":
        styleObject.color = '#000';
        styleObject.background =
          "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(4, 144, 243) 90%)";
        break;
      case "AVAILABLE":
        styleObject.color = '#000';
        styleObject.background =
          "linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)";
        break;
      default:
        break;
    }
    return styleObject;
  };

  const renderCell = (BinStatus, binType, binKey, pm_id, statusTitle) => {
    return (
      <td
        style={{
          padding: "4px",
          pointerEvents: isRestricted(BinStatus && BinStatus.group_type_id)
            ? "none"
            : isViewPermissionAllowed
            ? "auto"
            : "none",
          cursor: isRestricted(BinStatus && BinStatus.group_type_id)
            ? "not-allowed"
            : isViewPermissionAllowed
            ? "pointer"
            : "not-allowed",
          //   width: "85px",
        }}
      >
        <div
          title={statusTitle}
          data-toggle="tooltip"
          className="tooltip-test text-primary text-center fw-bolder fs-12"
        >
          <div
            id={binKey}
            style={{
              border: "1px solid gray",
              borderRadius: "12px",
              padding: "10px",
              background: getColor(statusTitle).background,
              color: getColor(statusTitle).color
            }}
            onClick={() => openbin(binKey, binType, pm_id)}
          >
            {isRestricted(BinStatus && BinStatus.group_type_id) && (
              <CiLock size="18px" color="#000" fontWeight={700} />
            )}
            &nbsp;{binKey}
          </div>
        </div>
      </td>
    );
  };

  const renderMiddleColumnCells = (
    n,
    n1,
    elem3,
    BinStatus,
    binType,
    binKey,
    pm_id,
    statusTitle
  ) => {
    if (n === 1 && n1 === 0) {
      return (
        <td
          rowSpan={2}
          style={{
            padding: "4px",
            width: "85px",
            height: "90px",
            pointerEvents: isRestricted(
              BinStatus && BinStatus.group_type_id
            )
              ? "none"
              : isViewPermissionAllowed
                ? "auto"
                : "none",
            cursor: isRestricted(BinStatus && BinStatus.group_type_id)
              ? "not-allowed"
              : isViewPermissionAllowed
                ? "pointer"
                : "not-allowed",
          }}
          onClick={() => openbin(elem3[n] + "" + (n1 + 1), binType, pm_id)}
        >
          <div
            title={statusTitle}
            data-toggle="tooltip"
            className="tooltip-test text-primary text-center fw-bolder fs-12"
          >
            <div
              id={elem3[n] + "" + (n1 + 1)}
              style={{
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid gray",
                borderRadius: "12px",
                padding: "10px",
                background: getColor(statusTitle).background,
                color: getColor(statusTitle).color
              }}
            >
              {isRestricted(BinStatus && BinStatus.group_type_id) && (
                <CiLock size="18px" color="#000" fontWeight={700} />
              )}
              &nbsp;{elem3[n] + "" + (n1 + 1)}
            </div>
          </div>
        </td>
      );
    }
    if (n === 1 && n1 === 2) {
      return (
        <td
          rowSpan={2}
          style={{
            padding: "4px",
            width: "85px",
            height: "90px",
            pointerEvents: isRestricted(
              BinStatus && BinStatus.group_type_id
            )
              ? "none"
              : isViewPermissionAllowed
                ? "auto"
                : "none",
            cursor: isRestricted(BinStatus && BinStatus.group_type_id)
              ? "not-allowed"
              : isViewPermissionAllowed
                ? "pointer"
                : "not-allowed",
          }}
          onClick={() => openbin(elem3[n] + "" + n1, binType, pm_id)}
        >
          <div
            title={statusTitle}
            data-toggle="tooltip"
            className="tooltip-test text-primary text-center fw-bolder fs-12"
          >
            <div
              id={elem3[n] + "" + n1}
              style={{
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid gray",
                borderRadius: "12px",
                padding: "10px",
                background: getColor(statusTitle).background,
                color: getColor(statusTitle).color
              }}
            >
              {isRestricted(BinStatus && BinStatus.group_type_id) && (
                <CiLock size="18px" color="#000" fontWeight={700} />
              )}
              &nbsp;{elem3[n] + "" + n1}
            </div>
          </div>
        </td>
      );
    }
    if (n === 1 && [4, 5]?.includes(n1)) {
      return (
        <td
          style={{
            padding: "4px",
            width: "85px",
            pointerEvents: isRestricted(
              BinStatus && BinStatus.group_type_id
            )
              ? "none"
              : isViewPermissionAllowed
                ? "auto"
                : "none",
            cursor: isRestricted(BinStatus && BinStatus.group_type_id)
              ? "not-allowed"
              : isViewPermissionAllowed
                ? "pointer"
                : "not-allowed",
          }}
          onClick={() => openbin(elem3[n] + "" + (n1 - 1), binType, pm_id)}
        >
          <div
            title={statusTitle}
            data-toggle="tooltip"
            className="tooltip-test text-primary text-center fw-bolder fs-12"
          >
            <div
              id={elem3[n] + "" + (n1 - 1)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid gray",
                borderRadius: "12px",
                padding: "10px",
                background: getColor(statusTitle).background,
                color: getColor(statusTitle).color
              }}
            >
              {isRestricted(BinStatus && BinStatus.group_type_id) && (
                <CiLock size="18px" color="#000" fontWeight={700} />
              )}
              &nbsp;{elem3[n] + "" + (n1 - 1)}
            </div>
          </div>
        </td>
      );
    }
    if (n === 1 && n1 === 6) {
      return (
        <td
          rowSpan={3}
          style={{
            padding: "4px",
            width: "85px",
            height: "140px",
            pointerEvents: isRestricted(
              BinStatus && BinStatus.group_type_id
            )
              ? "none"
              : isViewPermissionAllowed
                ? "auto"
                : "none",
            cursor: isRestricted(BinStatus && BinStatus.group_type_id)
              ? "not-allowed"
              : isViewPermissionAllowed
                ? "pointer"
                : "not-allowed",
          }}
          onClick={() => openbin(elem3[n] + "" + (n1 - 1), binType, pm_id)}
        >
          <div
            title={statusTitle}
            data-toggle="tooltip"
            className="tooltip-test text-primary text-center fw-bolder fs-12"
          >
            <div
              id={elem3[n] + "" + (n1 - 1)}
              style={{
                height: "140px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid gray",
                borderRadius: "12px",
                padding: "10px",
                background: getColor(statusTitle).background,
                color: getColor(statusTitle).color
              }}
            >
              {isRestricted(BinStatus && BinStatus.group_type_id) && (
                <CiLock size="18px" color="#000" fontWeight={700} />
              )}
              &nbsp;{elem3[n] + "" + (n1 - 1)}
            </div>
          </div>
        </td>
      );
    }
    if (n === 1 && n1 === 9) {
      return (
        <td
          rowSpan={3}
          style={{
            padding: "4px",
            width: "85px",
            height: "140px",
            pointerEvents: isRestricted(
              BinStatus && BinStatus.group_type_id
            )
              ? "none"
              : isViewPermissionAllowed
                ? "auto"
                : "none",
            cursor: isRestricted(BinStatus && BinStatus.group_type_id)
              ? "not-allowed"
              : isViewPermissionAllowed
                ? "pointer"
                : "not-allowed",
          }}
          onClick={() => openbin(elem3[n] + "" + (n1 - 3), binType, pm_id)}
        >
          <div
            title={statusTitle}
            data-toggle="tooltip"
            className="tooltip-test text-primary text-center fw-bolder fs-12"
          >
            <div
              id={elem3[n] + "" + (n1 - 3)}
              style={{
                height: "140px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid gray",
                borderRadius: "12px",
                padding: "10px",
                background: getColor(statusTitle).background,
                color: getColor(statusTitle).color
              }}
            >
              {isRestricted(BinStatus && BinStatus.group_type_id) && (
                <CiLock size="18px" color="#000" fontWeight={700} />
              )}
              &nbsp;{elem3[n] + "" + (n1 - 3)}
            </div>
          </div>
        </td>
      );
    } else {
      return (
        n !== 1 &&
        n1 !== 1 && (
          <td
            style={{
              padding: "4px",
              width: "85px",
              pointerEvents: isRestricted(
                BinStatus && BinStatus.group_type_id
              )
                ? "none"
                : isViewPermissionAllowed
                  ? "auto"
                  : "none",
              cursor: isRestricted(BinStatus && BinStatus.group_type_id)
                ? "not-allowed"
                : isViewPermissionAllowed
                  ? "pointer"
                  : "not-allowed",
            }}
            onClick={() => openbin(binKey, binType, pm_id)}
          >
            <div
              title={statusTitle}
              data-toggle="tooltip"
              className="tooltip-test text-primary text-center fw-bolder fs-12"
            >
              <div
                id={elem3[n] + "" + (n1 + 1)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid gray",
                  borderRadius: "12px",
                  padding: "10px",
                  background: getColor(statusTitle).background,
                  color: getColor(statusTitle).color
                }}
              >
                {isRestricted(BinStatus && BinStatus.group_type_id) && (
                  <CiLock size="18px" color="#000" fontWeight={700} />
                )}
                &nbsp;{elem3[n] + "" + n1}
              </div>
            </div>
          </td>
        )
      );
    }
  };

  const getBinIndexForMiddleColumn = (n, n1, elem3) => {
    let index = null;
    if (n === 1 && n1 === 0) {
      index = `${elem3[n]}${n1 + 1}`;
    } else if (n === 1 && n1 === 2) {
      index = `${elem3[n]}${n1}`;
    } else if (n === 1 && [4, 5].includes(n1)) {
      index = `${elem3[n]}${n1 - 1}`;
    } else if (n === 1 && n1 === 6) {
      index = `${elem3[n]}${n1 - 1}`;
    } else if (n === 1 && n1 === 9) {
      index = `${elem3[n]}${n1 - 3}`;
    } else {
      index =  n === 1 ? (`${elem3[n]}${n1}`) : (`${elem3[n]}${n1 + 1}`);
    }
    return index;
  };

  return (
    <div className="container">
      <table
        style={{
          pointerEvents: isViewPermissionAllowed ? "auto" : "none",
        }}
      >
        {nums1.map((n1) => {
          return (
            <tr>
              {nums3.map((n) => {
                if (n !== 1) {
                  let binKey = getBinIndexForMiddleColumn(n, n1, elem3);
                  if (BinStatus && BinStatus.length && BinStatus.length > 0) {
                    for (var i = 0; i < BinStatus.length; i++) {
                      let pm_id = BinStatus[i].id;
                      let bin_id = BinStatus[i].bin_id;
                      let type_id = BinStatus[i].type_id;
                      if (bin_id === binKey) {
                        if (type_id === BOX_STATUS.STOCKED) {
                          return renderCell(
                            BinStatus[i],
                            "Stocked",
                            binKey,
                            pm_id,
                            "STOCKED"
                          );
                        }
                        if (type_id === BOX_STATUS.ASSIGNED) {
                          return renderCell(
                            BinStatus[i],
                            "Assigned",
                            binKey,
                            pm_id,
                            "ASSIGNED"
                          );
                        }
                        if (type_id === BOX_STATUS.HOLD) {
                          return renderCell(
                            BinStatus[i],
                            "Hold",
                            binKey,
                            pm_id,
                            "HOLD"
                          );
                        }
                      }
                    }
                    return renderCell(
                      BinStatus[i],
                      "Available",
                      binKey,
                      null,
                      "AVAILABLE"
                    );
                  } else {
                    return renderCell(
                      BinStatus[i],
                      "Available",
                      binKey,
                      null,
                      "AVAILABLE"
                    );
                  }
                } else {
                  let binKey = getBinIndexForMiddleColumn(n, n1, elem3);
                  if (BinStatus && BinStatus.length && BinStatus.length > 0) {
                    for (var j = 0; j < BinStatus.length; j++) {
                      let pm_id = BinStatus[j].id;
                      let bin_id = BinStatus[j].bin_id;
                      let type_id = BinStatus[j].type_id;
                      if (bin_id === binKey) {
                        if (type_id === BOX_STATUS.STOCKED) {
                          return renderMiddleColumnCells(
                            n,
                            n1,
                            elem3,
                            BinStatus[j],
                            "Stocked",
                            binKey,
                            pm_id,
                            "STOCKED"
                          );
                        }
                        if (type_id === BOX_STATUS.ASSIGNED) {
                          return renderMiddleColumnCells(
                            n,
                            n1,
                            elem3,
                            BinStatus[j],
                            "Assigned",
                            binKey,
                            pm_id,
                            "ASSIGNED"
                          );
                        }
                        if (type_id === BOX_STATUS.HOLD) {
                          return renderMiddleColumnCells(
                            n,
                            n1,
                            elem3,
                            BinStatus[j],
                            "Hold",
                            binKey,
                            pm_id,
                            "HOLD"
                          );
                        }
                      }
                    }
                    return renderMiddleColumnCells(
                      n,
                      n1,
                      elem3,
                      BinStatus[i],
                      "Available",
                      binKey,
                      null,
                      "AVAILABLE"
                    );
                  } else {
                    return renderMiddleColumnCells(
                      n,
                      n1,
                      elem3,
                      BinStatus[i],
                      "Available",
                      binKey,
                      null,
                      "AVAILABLE"
                    );
                  }
                }
              })}
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default C30Layout;
