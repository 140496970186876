import React from "react";
import BOX_STATUS from "../../../constant/BoxStatus";
import GroupTypes from "../../../constant/GroupTypes";
import { CiLock } from "react-icons/ci";

let nums = Array.from(Array(5).keys());
let nums1 = Array.from(Array(12).keys());
let elem = ["A", "B", "C", "D", "E"];
// let deactivatedBin = [];
let BinIds = [];
let IsSaved;

const Main46Layout = ({
  BinStatus,
  deactivatedBins,
  getPrescriptionDetails,
  Selectedbin,
  isViewPermissionAllowed,
}) => {
  const checkIsSaved = (binId) => {
    if (BinStatus && BinStatus.length && BinStatus.length > 0) {
      BinStatus.map((binitem) => {
        if (binitem.bin_id === binId) {
          IsSaved = true;
        } else {
          IsSaved = false;
        }
        return true;
      });
    } else {
      IsSaved = false;
    }
  };

  const openbin = (binId, status, groupTypeId) => {
    if (
      ![
        GroupTypes.Pharmacy,
        GroupTypes.Super,
        GroupTypes.eCommerce,
        GroupTypes.Audiology,
      ].includes(groupTypeId)
    ) {
      if (status === "Assigned" || status === "Stocked" || status === "Hold") {
        getPrescriptionDetails(binId);
      } else {
        BinIds.map((item) => {
          checkIsSaved(item);
          if (item !== binId && IsSaved === false) {
            var items = document.getElementById(item);
            items.style.background =
              "linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)";
          }
          return true;
        });
        BinIds = [];
        BinIds.push(binId);
        if (BinIds.length > 0) {
          Selectedbin(binId);
          var items = document.getElementById(binId);
          items.style.background =
            "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)";
        }
      }
    }
  };

  return (
    <div className="container">
      <div className="row">
        {nums1.map((n1) => {
          return (
            <div key={n1} className="row g-1 gx-1">
              {nums.map((n) => {
                if (
                  (elem[n] === "B" || elem[n] === "C") &&
                  n1 >= 0 &&
                  n1 <= 6
                ) {
                  if (elem[n] === "B") {
                    return (
                      <div
                        key={n}
                        id={elem[n] + "" + (n1 + 1)}
                        className="col-2"
                      />
                    );
                  } else {
                    return (
                      <div
                        key={n}
                        id={elem[n] + "" + (n1 + 1)}
                        className="col-2"
                      />
                    );
                  }
                } else {
                  if (elem[n] === "A" || elem[n] === "B" || elem[n] === "C") {
                    if (BinStatus && BinStatus.length && BinStatus.length > 0) {
                      let BinKey = elem[n] + (n1 + 1);
                      for (var i = 0; i < BinStatus.length; i++) {
                        let groupTypeId = BinStatus[i]?.group_type_id;
                        if (
                          BinStatus[i].type_id === BOX_STATUS.STOCKED &&
                          BinStatus[i].bin_id === BinKey
                        ) {
                          return (
                            <div
                              title="Stocked"
                              data-toggle="tooltip"
                              className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                            >
                              <div
                                id={elem[n] + "" + (n1 + 1)}
                                key={elem[n] + " " + (n1 + 1)}
                                style={{
                                  border: "1px solid gray",
                                  borderRadius: "12px",
                                  padding: "10px 15px",
                                  background:
                                    "linear-gradient(155deg, rgb(23,166,0) 0%, rgb(196,255,189) 90%)",
                                  pointerEvents: isViewPermissionAllowed
                                    ? "auto"
                                    : "none",
                                  cursor:
                                    (BinStatus[i] && [
                                      GroupTypes.Pharmacy,
                                      GroupTypes.Super,
                                      GroupTypes.eCommerce,
                                      GroupTypes.Audiology
                                    ].includes(BinStatus[i].group_type_id))
                                      ? "not-allowed"
                                      : isViewPermissionAllowed
                                        ? "pointer"
                                        : "not-allowed",
                                }}
                                onClick={() =>
                                  openbin(
                                    elem[n] + "" + (n1 + 1),
                                    "Stocked",
                                    groupTypeId
                                  )
                                }
                              >
                                {(BinStatus[i] && [
                                  GroupTypes.Pharmacy,
                                  GroupTypes.Super,
                                  GroupTypes.eCommerce,
                                  GroupTypes.Audiology
                                ].includes(BinStatus[i].group_type_id))
                                  ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                &nbsp;{elem[n] + " " + (n1 + 1)}
                              </div>
                            </div>
                          );
                        } else if (
                          BinStatus[i].type_id === BOX_STATUS.ASSIGNED &&
                          BinStatus[i].bin_id === BinKey
                        ) {
                          return (
                            <div
                              title="Assigned"
                              data-toggle="tooltip"
                              className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                            >
                              <div
                                id={elem[n] + "" + (n1 + 1)}
                                key={elem[n] + " " + (n1 + 1)}
                                style={{
                                  border: "1px solid gray",
                                  borderRadius: "12px",
                                  padding: "10px 15px",
                                  background:
                                    "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)",
                                  pointerEvents: isViewPermissionAllowed
                                    ? "auto"
                                    : "none",
                                  cursor:
                                    (BinStatus[i] && [
                                      GroupTypes.Pharmacy,
                                      GroupTypes.Super,
                                      GroupTypes.eCommerce,
                                      GroupTypes.Audiology
                                    ].includes(BinStatus[i].group_type_id))
                                      ? "not-allowed"
                                      : isViewPermissionAllowed
                                        ? "pointer"
                                        : "not-allowed",
                                }}
                                onClick={() =>
                                  openbin(
                                    elem[n] + "" + (n1 + 1),
                                    "Assigned",
                                    groupTypeId
                                  )
                                }
                              >
                                {(BinStatus[i] && [
                                  GroupTypes.Pharmacy,
                                  GroupTypes.Super,
                                  GroupTypes.eCommerce,
                                  GroupTypes.Audiology
                                ].includes(BinStatus[i].group_type_id)) ? (
                                  <CiLock
                                    size="18px"
                                    color="#000"
                                    fontWeight={700}
                                  />
                                ) : null}
                                &nbsp;{elem[n] + " " + (n1 + 1)}
                              </div>
                            </div>
                          );
                        } else if (
                          BinStatus[i].type_id === BOX_STATUS.HOLD &&
                          BinStatus[i].bin_id === BinKey
                        ) {
                          return (
                            <div
                              title="Hold"
                              data-toggle="tooltip"
                              className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                            >
                              <div
                                id={elem[n] + "" + (n1 + 1)}
                                key={elem[n] + " " + (n1 + 1)}
                                style={{
                                  border: "1px solid gray",
                                  borderRadius: "12px",
                                  padding: "10px 15px",
                                  background:
                                    "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(4, 144, 243) 90%)",
                                  pointerEvents: isViewPermissionAllowed
                                    ? "auto"
                                    : "none",
                                  cursor:
                                    (BinStatus[i] && [
                                      GroupTypes.Pharmacy,
                                      GroupTypes.Super,
                                      GroupTypes.eCommerce,
                                      GroupTypes.Audiology
                                    ].includes(BinStatus[i].group_type_id))
                                      ? "not-allowed"
                                      : isViewPermissionAllowed
                                        ? "pointer"
                                        : "not-allowed",
                                }}
                                onClick={() =>
                                  openbin(
                                    elem[n] + "" + (n1 + 1),
                                    "Hold",
                                    groupTypeId
                                  )
                                }
                              >
                                {(BinStatus[i] && [
                                  GroupTypes.Pharmacy,
                                  GroupTypes.Super,
                                  GroupTypes.eCommerce,
                                  GroupTypes.Audiology
                                ].includes(BinStatus[i].group_type_id)) ? (
                                  <CiLock
                                    size="18px"
                                    color="#000"
                                    fontWeight={700}
                                  />
                                ) : null}
                                &nbsp;{elem[n] + " " + (n1 + 1)}
                              </div>
                            </div>
                          );
                        }
                      }
                      if (
                        deactivatedBins &&
                        deactivatedBins.length &&
                        deactivatedBins.length > 0 &&
                        deactivatedBins.findIndex(
                          (D) => D === elem[n] + (n1 + 1)
                        ) > -1
                      ) {
                        return (
                          <div
                            title="DEACTIVATE"
                            data-toggle="tooltip"
                            className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                          >
                            <div
                              id={elem[n] + "" + (n1 + 1)}
                              key={elem[n] + " " + (n1 + 1)}
                              style={{
                                border: "1px solid gray",
                                borderRadius: "12px",
                                padding: "10px 15px",
                                background:
                                  "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                                pointerEvents: isViewPermissionAllowed
                                  ? "auto"
                                  : "none",
                                cursor:
                                  (BinStatus[i] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(BinStatus[i].group_type_id))
                                    ? "not-allowed"
                                    : isViewPermissionAllowed
                                      ? "pointer"
                                      : "not-allowed",
                              }}
                            >
                              {(BinStatus[i] && [
                                GroupTypes.Pharmacy,
                                GroupTypes.Super,
                                GroupTypes.eCommerce,
                                GroupTypes.Audiology
                              ].includes(BinStatus[i].group_type_id)) ? (
                                <CiLock
                                  size="18px"
                                  color="#000"
                                  fontWeight={700}
                                />
                              ) : null}
                              &nbsp;{elem[n] + " " + (n1 + 1)}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            title="Available"
                            data-toggle="tooltip"
                            className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                          >
                            <div
                              id={elem[n] + "" + (n1 + 1)}
                              key={elem[n] + " " + (n1 + 1)}
                              style={{
                                border: "1px solid gray",
                                borderRadius: "12px",
                                padding: "10px 15px",
                                background:
                                  "linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)",
                                pointerEvents: isViewPermissionAllowed
                                  ? "auto"
                                  : "none",
                                cursor:
                                  (BinStatus[i] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(BinStatus[i].group_type_id))
                                    ? "not-allowed"
                                    : isViewPermissionAllowed
                                      ? "pointer"
                                      : "not-allowed",
                              }}
                              onClick={() =>
                                openbin(
                                  elem[n] + "" + (n1 + 1),
                                  null,
                                  BinStatus[i]?.group_type_id
                                )
                              }
                            >
                              {(BinStatus[i] && [
                                GroupTypes.Pharmacy,
                                GroupTypes.Super,
                                GroupTypes.eCommerce,
                                GroupTypes.Audiology
                              ].includes(BinStatus[i].group_type_id)) ? (
                                <CiLock
                                  size="18px"
                                  color="#000"
                                  fontWeight={700}
                                />
                              ) : null}
                              &nbsp;{elem[n] + " " + (n1 + 1)}
                            </div>
                          </div>
                        );
                      }
                    } else {
                      if (
                        deactivatedBins.length > 0 &&
                        deactivatedBins.findIndex(
                          (D) => D === elem[n] + (n1 + 1)
                        ) > -1
                      ) {
                        return (
                          <div
                            title="DEACTIVATE"
                            data-toggle="tooltip"
                            className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                          >
                            <div
                              id={elem[n] + "" + (n1 + 1)}
                              key={elem[n] + " " + (n1 + 1)}
                              style={{
                                border: "1px solid gray",
                                borderRadius: "12px",
                                padding: "10px 15px",
                                background:
                                  "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                                pointerEvents: isViewPermissionAllowed
                                  ? "auto"
                                  : "none",
                                cursor:
                                  (BinStatus[i] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(BinStatus[i].group_type_id))
                                    ? "not-allowed"
                                    : isViewPermissionAllowed
                                      ? "pointer"
                                      : "not-allowed",
                              }}
                            >
                              {(BinStatus[i] && [
                                GroupTypes.Pharmacy,
                                GroupTypes.Super,
                                GroupTypes.eCommerce,
                                GroupTypes.Audiology
                              ].includes(BinStatus[i].group_type_id)) ? (
                                <CiLock
                                  size="18px"
                                  color="#000"
                                  fontWeight={700}
                                />
                              ) : null}
                              &nbsp;{elem[n] + " " + (n1 + 1)}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            title="Available"
                            data-toggle="tooltip"
                            className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                          >
                            <div
                              id={elem[n] + "" + (n1 + 1)}
                              key={elem[n] + " " + (n1 + 1)}
                              style={{
                                border: "1px solid gray",
                                borderRadius: "12px",
                                padding: "10px 15px",
                                background:
                                  "linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)",
                                pointerEvents: isViewPermissionAllowed
                                  ? "auto"
                                  : "none",
                                cursor:
                                  (BinStatus[i] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(BinStatus[i].group_type_id))
                                    ? "not-allowed"
                                    : isViewPermissionAllowed
                                      ? "pointer"
                                      : "not-allowed",
                              }}
                              onClick={() =>
                                openbin(
                                  elem[n] + "" + (n1 + 1),
                                  null,
                                  BinStatus[i]?.group_type_id
                                )
                              }
                            >
                              {(BinStatus[i] && [
                                GroupTypes.Pharmacy,
                                GroupTypes.Super,
                                GroupTypes.eCommerce,
                                GroupTypes.Audiology
                              ].includes(BinStatus[i].group_type_id)) ? (
                                <CiLock
                                  size="18px"
                                  color="#000"
                                  fontWeight={700}
                                />
                              ) : null}
                              &nbsp;{elem[n] + " " + (n1 + 1)}
                            </div>
                          </div>
                        );
                      }
                    }
                  } else {
                    if (BinStatus && BinStatus.length > 0) {
                      let BinKey = elem[n] + (n1 + 1);
                      for (let j = 0; j < BinStatus.length; j++) {
                        let groupTypeId = BinStatus[j]?.group_type_id;
                        if (
                          BinStatus[j].type_id === BOX_STATUS.STOCKED &&
                          BinStatus[j].bin_id === BinKey
                        ) {
                          if (deactivatedBins.includes(BinKey)) {
                            return (
                              <div
                                title="DEACTIVATE"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                >
                                  {(BinStatus[i] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(BinStatus[i].group_type_id)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                title="Stocked"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(23,166,0) 0%, rgb(196,255,189) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[j] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[j].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                  onClick={() =>
                                    openbin(
                                      elem[n] + "" + (n1 + 1),
                                      "Stocked",
                                      groupTypeId
                                    )
                                  }
                                >
                                  {(BinStatus[j] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(groupTypeId)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          }
                        } else if (
                          BinStatus[j].type_id === BOX_STATUS.ASSIGNED &&
                          BinStatus[j].bin_id === BinKey
                        ) {
                          if (deactivatedBins.includes(BinKey)) {
                            return (
                              <div
                                title="DEACTIVATE"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                >
                                  {(BinStatus[i] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(BinStatus[i].group_type_id)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                title="Assigned"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                  onClick={() =>
                                    openbin(
                                      elem[n] + "" + (n1 + 1),
                                      "Assigned",
                                      groupTypeId
                                    )
                                  }
                                >
                                  {(BinStatus[j] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(groupTypeId)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          }
                        } else if (
                          BinStatus[j].type_id === BOX_STATUS.HOLD &&
                          BinStatus[j].bin_id === BinKey
                        ) {
                          if (deactivatedBins.includes(BinKey)) {
                            return (
                              <div
                                title="DEACTIVATE"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                >
                                  {(BinStatus[i] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(BinStatus[i].group_type_id)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                title="Hold"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(4, 144, 243) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                  onClick={() =>
                                    openbin(
                                      elem[n] + "" + (n1 + 1),
                                      "Hold",
                                      groupTypeId
                                    )
                                  }
                                >
                                  {(BinStatus[j] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(groupTypeId)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          }
                        }
                      }
                    }
                    if (BinStatus && BinStatus.length > 0) {
                      var BinKey = elem[n] + (n1 + 1);
                      for (let j = 0; j < BinStatus.length; j++) {
                        let groupTypeId = BinStatus[j]?.group_type_id;
                        if (
                          BinStatus[j].type_id === BOX_STATUS.STOCKED &&
                          BinStatus[j].bin_id === BinKey
                        ) {
                          if (deactivatedBins.includes(BinKey)) {
                            return (
                              <div
                                title="DEACTIVATE"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                >
                                  {(BinStatus[i] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(BinStatus[i].group_type_id)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                title="Stocked"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(23,166,0) 0%, rgb(196,255,189) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                  onClick={() =>
                                    openbin(
                                      elem[n] + "" + (n1 + 1),
                                      "Stocked",
                                      groupTypeId
                                    )
                                  }
                                >
                                  {(BinStatus[j] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(groupTypeId)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          }
                        } else if (
                          BinStatus[j].type_id === BOX_STATUS.ASSIGNED &&
                          BinStatus[j].bin_id === BinKey
                        ) {
                          if (deactivatedBins.includes(BinKey)) {
                            return (
                              <div
                                title="DEACTIVATE"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                >
                                  {(BinStatus[j] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(groupTypeId)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                title="Assigned"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(253, 253, 2) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                  onClick={() =>
                                    openbin(
                                      elem[n] + "" + (n1 + 1),
                                      "Assigned",
                                      groupTypeId
                                    )
                                  }
                                >
                                  {(BinStatus[j] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(groupTypeId)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          }
                        } else if (
                          BinStatus[j].type_id === BOX_STATUS.HOLD &&
                          BinStatus[j].bin_id === BinKey
                        ) {
                          if (deactivatedBins.includes(BinKey)) {
                            return (
                              <div
                                title="DEACTIVATE"
                                data-toggle="tooltip"
                                className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                              >
                                <div
                                  id={elem[n] + "" + (n1 + 1)}
                                  key={elem[n] + " " + (n1 + 1)}
                                  style={{
                                    border: "1px solid gray",
                                    borderRadius: "12px",
                                    padding: "10px 15px",
                                    background:
                                      "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                                    pointerEvents: isViewPermissionAllowed
                                      ? "auto"
                                      : "none",
                                    cursor:
                                      (BinStatus[i] && [
                                        GroupTypes.Pharmacy,
                                        GroupTypes.Super,
                                        GroupTypes.eCommerce,
                                        GroupTypes.Audiology
                                      ].includes(BinStatus[i].group_type_id))
                                        ? "not-allowed"
                                        : isViewPermissionAllowed
                                          ? "pointer"
                                          : "not-allowed",
                                  }}
                                >
                                  {(BinStatus[j] && [
                                    GroupTypes.Pharmacy,
                                    GroupTypes.Super,
                                    GroupTypes.eCommerce,
                                    GroupTypes.Audiology
                                  ].includes(groupTypeId)) ? (
                                    <CiLock
                                      size="18px"
                                      color="#000"
                                      fontWeight={700}
                                    />
                                  ) : null}
                                  &nbsp;{elem[n] + " " + (n1 + 1)}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                id={elem[n] + "" + (n1 + 1)}
                                key={elem[n] + " " + (n1 + 1)}
                                style={{
                                  border: "1px solid gray",
                                  borderRadius: "12px",
                                  padding: "10px 15px",
                                  background:
                                    "linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(4, 144, 243) 90%)",
                                  pointerEvents: isViewPermissionAllowed
                                    ? "auto"
                                    : "none",
                                  cursor:
                                    (BinStatus[i] && [
                                      GroupTypes.Pharmacy,
                                      GroupTypes.Super,
                                      GroupTypes.eCommerce,
                                      GroupTypes.Audiology
                                    ].includes(BinStatus[i].group_type_id))
                                      ? "not-allowed"
                                      : isViewPermissionAllowed
                                        ? "pointer"
                                        : "not-allowed",
                                }}
                                onClick={() =>
                                  openbin(
                                    elem[n] + "" + (n1 + 1),
                                    "Hold",
                                    groupTypeId
                                  )
                                }
                              >
                                {(BinStatus[i] && [
                                  GroupTypes.Pharmacy,
                                  GroupTypes.Super,
                                  GroupTypes.eCommerce,
                                  GroupTypes.Audiology
                                ].includes(BinStatus[i].group_type_id)) ? (
                                  <CiLock
                                    size="18px"
                                    color="#000"
                                    fontWeight={700}
                                  />
                                ) : null}
                                &nbsp;{elem[n] + " " + (n1 + 1)}
                              </div>
                            );
                          }
                        }
                      }
                    }
                    if (
                      deactivatedBins.length > 0 &&
                      deactivatedBins.findIndex(
                        (D) => D === elem[n] + (n1 + 1)
                      ) > -1
                    ) {
                      return (
                        <div
                          title="DEACTIVATE"
                          data-toggle="tooltip"
                          className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                        >
                          <div
                            id={elem[n] + "" + (n1 + 1)}
                            key={elem[n] + " " + (n1 + 1)}
                            style={{
                              border: "1px solid gray",
                              borderRadius: "12px",
                              padding: "10px 15px",
                              background:
                                "linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)",
                              pointerEvents: isViewPermissionAllowed
                                ? "auto"
                                : "none",
                              cursor:
                                (BinStatus[i] && [
                                  GroupTypes.Pharmacy,
                                  GroupTypes.Super,
                                  GroupTypes.eCommerce,
                                  GroupTypes.Audiology
                                ].includes(BinStatus[i].group_type_id))
                                  ? "not-allowed"
                                  : isViewPermissionAllowed
                                    ? "pointer"
                                    : "not-allowed",
                            }}
                          >
                            {(BinStatus[i] && [
                              GroupTypes.Pharmacy,
                              GroupTypes.Super,
                              GroupTypes.eCommerce,
                              GroupTypes.Audiology
                            ].includes(BinStatus[i].group_type_id)) ? (
                              <CiLock
                                size="18px"
                                color="#000"
                                fontWeight={700}
                              />
                            ) : null}
                            &nbsp;{elem[n] + " " + (n1 + 1)}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          title="Available"
                          data-toggle="tooltip"
                          className={elem[n] === "A" ? 'tooltip-test text-dark col-3 text-center fw-bolder fs-12' : 'tooltip-test text-dark col-2 text-center fw-bolder fs-12'}
                        >
                          <div
                            id={elem[n] + "" + (n1 + 1)}
                            key={elem[n] + " " + (n1 + 1)}
                            style={{
                              border: "1px solid gray",
                              borderRadius: "12px",
                              padding: "10px 15px",
                              background:
                                "linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)",
                              pointerEvents: isViewPermissionAllowed
                                ? "auto"
                                : "none",
                              cursor:
                                (BinStatus[i] && [
                                  GroupTypes.Pharmacy,
                                  GroupTypes.Super,
                                  GroupTypes.eCommerce,
                                  GroupTypes.Audiology
                                ].includes(BinStatus[i].group_type_id))
                                  ? "not-allowed"
                                  : isViewPermissionAllowed
                                    ? "pointer"
                                    : "not-allowed",
                            }}
                            onClick={() =>
                              openbin(
                                elem[n] + "" + (n1 + 1),
                                null,
                                BinStatus[i]?.group_type_id
                              )
                            }
                          >
                            {(BinStatus[i] && [
                              GroupTypes.Pharmacy,
                              GroupTypes.Super,
                              GroupTypes.eCommerce,
                              GroupTypes.Audiology
                            ].includes(BinStatus[i].group_type_id)) ? (
                              <CiLock
                                size="18px"
                                color="#000"
                                fontWeight={700}
                              />
                            ) : null}
                            &nbsp;{elem[n] + " " + (n1 + 1)}
                          </div>
                        </div>
                      );
                    }
                  }
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Main46Layout;
